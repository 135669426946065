import {MenuListItem} from '@prescriberpoint/ui';
import {FC} from 'react';
import {Step} from '.';

interface IBaseSteps {
  steps: Step[];
  onClick: (_value: number) => void;
}

const BaseStep: FC<IBaseSteps> = ({steps, onClick}) =>
  steps.map((item: Step) => (
    <button
      key={item.value}
      className='w-full bg-transparent'
      onClick={() => onClick(item.value)}>
      <MenuListItem
        content={item.name}
        className='px-0'
        contentClassName='text-neutral-primary !pl-0 capitalize'
      />
    </button>
  ));

export default BaseStep;
