import {ActionListItemV2, Modal, toSnakeCase} from '@prescriberpoint/ui';
import {useMemo, useState, useRef, useCallback, FC} from 'react';
import {useCookies} from 'react-cookie';
import NewShare, {IShareData} from '../NewShare';
import {
  getLinkParentSectionFromResource,
  getLinkSectionFromResource,
} from '../ResourceLink';
import Share from '../Share';
import {SPLIT_USER_COOKIE} from '@/constants/cookies';
import {AFFORDABILITY_ON_PDP, NEW_VERSION_DS} from '@/constants/flags';
import {
  CEVENT_BRAND_RSC_CLICK,
  CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE,
  CEVENT_BRAND_RSC_SHARED,
  DlvBrandRscContinueToWebsite,
  DlvBrandRscShared,
  DlvRscClick,
} from '@/constants/gtm';
import {useFlag, useUserAgentContext} from '@/context';
import {useCurrentDrug, useEnhanced, useFirePaywall} from '@/hooks';
import {EnhancedContentLinkDto, RecentDocumentDto} from '@/models';
import {useCreateShareRequest} from '@/mutations';
import {formatISOtoString} from '@/utils/date';
import {
  appendToArrayVariable,
  customEvent,
  getEmailDomain,
  proccessSectionId,
} from '@/utils/gtm';
import {
  getResourceImg,
  isEnhancedContentLink,
  isMostRecentPaResource,
} from '@/utils/resources';

interface ActionListItemWithShareProps {
  resource: EnhancedContentLinkDto | RecentDocumentDto;
  isMostRecent?: boolean;
  type: 'download' | 'open';
}

type ShareSection = 'visit' | 'share';

type ShareModalProps = {
  resource: EnhancedContentLinkDto | RecentDocumentDto;
  isShareOpen: boolean;
  closeShare: () => void;
  renderUnderButton: boolean;
  shareSubmitted: boolean;
  handleSubmit: (_submitData: IShareData) => Promise<void>;
  linkParentSection: string;
  linkSection: string;
  shareSection: ShareSection;
};

const ShareModal: FC<ShareModalProps> = ({
  resource,
  isShareOpen,
  closeShare,
  renderUnderButton,
  shareSubmitted,
  handleSubmit,
  linkParentSection,
  linkSection,
  shareSection,
}) => {
  const {drugName} = useCurrentDrug();
  const {isMobileOrTablet} = useUserAgentContext();
  const showNewDs = useFlag(NEW_VERSION_DS);

  return showNewDs ? (
    <Modal
      isOpen={isShareOpen}
      closeModal={closeShare}
      wrapperClassName='!h-dvh'
      className='md:!max-w-[420px]'
      bodyClassName='!pt-0 !pb-5'
      position={isMobileOrTablet ? 'side' : 'center'}
      showCancel={false}
      showConfirm={false}>
      <NewShare
        shareTitle='Share this Resource'
        confirmationTitle='Resource Shared!'
        confirmationMessage={`The ${drugName} resource link has been shared!`}
        onCloseClick={closeShare}
        showConfirmation={shareSubmitted}
        onSubmit={handleSubmit}
      />
    </Modal>
  ) : (
    <Share
      id={`${toSnakeCase(resource?.title)}_share`}
      drugName={drugName}
      isShareOpen={isShareOpen}
      handleClose={closeShare}
      renderUnderButton={renderUnderButton}
      shareLink={resource?.href ?? ''}
      resource={resource?.title ?? ''}
      resourceParentSection={linkParentSection}
      resourceSection={linkSection}
      section={shareSection}
    />
  );
};

const ActionListItemWithShare = ({
  resource,
  isMostRecent,
  type,
}: ActionListItemWithShareProps) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [shareSection, setShareSection] = useState<ShareSection>('visit');
  const [renderUnderButton, setRenderUnderButton] = useState(false);
  const [shareSubmitted, setShareSubmitted] = useState(false);
  const {mutateAsync: createShareRequest} = useCreateShareRequest();
  const {drugName, setId} = useCurrentDrug();
  const firePaywall = useFirePaywall();
  const affordabilityOnPdp = useFlag(AFFORDABILITY_ON_PDP);
  const [cookies] = useCookies(['hubspotutk', SPLIT_USER_COOKIE]);
  const buttonRef = useRef<HTMLDivElement>(null);
  const {disableBrandShare} = useEnhanced(setId);

  const linkParentSection = useMemo(() => {
    if (isMostRecent) {
      return 'parentSectionPriorAuthorization';
    }
    if (isEnhancedContentLink(resource)) {
      return getLinkParentSectionFromResource(resource);
    }
    return '';
  }, [resource, isMostRecent]);

  const linkSection = useMemo(() => {
    if (isMostRecent) {
      return 'sectionRecentPriorAuthorizationForms';
    }
    if (isEnhancedContentLink(resource)) {
      return getLinkSectionFromResource(resource);
    }
    return '';
  }, [resource, isMostRecent]);

  const fireCustomEvent = useCallback(() => {
    customEvent<DlvRscClick>(CEVENT_BRAND_RSC_CLICK, {
      drugName,
      resourceName: resource.title,
      resourceParentSection: proccessSectionId(linkParentSection),
      resourceSection: proccessSectionId(linkSection),
      resourceImage: getResourceImg(resource.title, drugName),
      redirectUrl: resource.href,
      firePaywall: firePaywall,
      userKey: cookies[SPLIT_USER_COOKIE],
      experimentName: AFFORDABILITY_ON_PDP,
      treatment: affordabilityOnPdp,
    });
  }, [
    affordabilityOnPdp,
    cookies,
    drugName,
    firePaywall,
    linkParentSection,
    linkSection,
    resource.href,
    resource.title,
  ]);

  const defineSharePosition = () => {
    const {bottom} = buttonRef?.current?.getBoundingClientRect() ?? {bottom: 0};
    const shareHeight = 320;
    const distanceToViewportBottom = window.innerHeight - bottom;

    if (distanceToViewportBottom > shareHeight) {
      setRenderUnderButton(true);
    } else {
      setRenderUnderButton(false);
    }
  };

  const closeShare = () => {
    setIsShareOpen(false);
    setShareSubmitted(false);
  };

  const handleOpenShare = useCallback(() => {
    appendToArrayVariable('links', resource.href);
    if (resource?.title && resource?.href) {
      defineSharePosition();
      setIsShareOpen(true);
    }
  }, [resource]);

  const handleSubmit = async (shareData: IShareData) => {
    const {email, fromName} = shareData;

    const data = {
      brand: drugName,
      resource: resource?.title,
      target: email,
      type: 'email',
      link: resource?.href,
      senderName: fromName,
    };

    await createShareRequest(data);

    customEvent<DlvBrandRscShared>(CEVENT_BRAND_RSC_SHARED, {
      drugName: drugName,
      resourceName: resource?.title,
      emailDomain: getEmailDomain(email),
      shareType: 'email',
      link: resource?.href,
      resourceSection: proccessSectionId(linkSection ?? ''),
      resourceParentSection: proccessSectionId(linkParentSection ?? ''),
    });
    setShareSubmitted(true);
  };

  const handleShareClick = useCallback(() => {
    fireCustomEvent();
    if (!firePaywall) {
      setShareSection('share');
      handleOpenShare();
    }
  }, [firePaywall, fireCustomEvent, handleOpenShare]);

  // When clicking on the resource name or first action item we don't show the interstitial modal
  const handleActionClick = useCallback(() => {
    fireCustomEvent();
    customEvent<DlvBrandRscContinueToWebsite>(
      CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE,
      {
        drugName: drugName,
        resourceName: resource.title,
        link: resource.href,
        userKey: cookies[SPLIT_USER_COOKIE],
      },
    );
    if (!firePaywall) {
      window.open(resource.href, '_blank');
    }
  }, [firePaywall, fireCustomEvent, resource, cookies, drugName]);

  return (
    <div ref={buttonRef} className='relative list-none'>
      <ActionListItemV2
        title={resource.title}
        tagFirstText={
          isMostRecentPaResource(resource)
            ? `Verified: ${formatISOtoString(resource.updatedDate)}`
            : ''
        }
        firstAction={type}
        onOpen={type === 'open' ? handleActionClick : undefined}
        onDownload={type === 'download' ? handleActionClick : undefined}
        onShare={disableBrandShare ? undefined : handleShareClick}
      />
      {disableBrandShare ? null : (
        <ShareModal
          resource={resource}
          isShareOpen={isShareOpen}
          closeShare={closeShare}
          renderUnderButton={renderUnderButton}
          shareSubmitted={shareSubmitted}
          handleSubmit={handleSubmit}
          linkParentSection={linkParentSection}
          linkSection={linkSection}
          shareSection={shareSection}
        />
      )}
    </div>
  );
};

ActionListItemWithShare.displayName = 'ActionListItemWithShare';

export default ActionListItemWithShare;
