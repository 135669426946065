import {
  ArrowDownUpIcon,
  BookOpenTextIcon,
  ClipboardPlusIcon,
  FilePieChartIcon,
  HandCoinsIcon,
} from 'lucide-react';
import {CtaDataType, CtaSectionType} from '../models';
import {SECTION_IDS} from '@/constants/sectionIDs';

export const CTA_DATA: Record<CtaSectionType, CtaDataType> = {
  coverage: {
    text: 'Check Coverage\nRestrictions',
    icon: FilePieChartIcon,
    anchor: SECTION_IDS.coverage,
  },
  interactions: {
    text: 'Check Drug\nInteractions',
    icon: ArrowDownUpIcon,
  },
  financialAssistance: {
    text: 'Financial Assistance\nPrograms',
    icon: HandCoinsIcon,
    anchor: SECTION_IDS.financialAssistance,
  },
  priorAuthorization: {
    text: 'Get Prior\nAuthorization Forms',
    icon: ClipboardPlusIcon,
    anchor: SECTION_IDS.priorAuthorization,
  },
  patientEducation: {
    text: 'Get Patient\nEducation Materials',
    icon: BookOpenTextIcon,
    anchor: SECTION_IDS.patientEducation,
  },
};
