import {FC} from 'react';
import BoxedWarning from './BoxedWarning';
import Dosages from './Dosages';
import Otc from './OTC';
import RemsInfo from './RemsInfo';
import HeroIcons from '@/components/HeroIcons';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug, useIsOtc} from '@/hooks';
import {useRemsBySetId} from '@/queries';

const ChipSection: FC = () => {
  const {setId} = useCurrentDrug();
  const {isMobileOrTablet} = useUserAgentContext();
  const isOtc = useIsOtc();

  const {data, isFetching} = useRemsBySetId(setId);
  const showRemsInfo = !!(data && !isFetching);

  return (
    <>
      {isOtc ? <Otc /> : null}
      <Dosages />
      {showRemsInfo && !isMobileOrTablet ? <RemsInfo /> : null}
      <BoxedWarning />
      {!isMobileOrTablet ? <HeroIcons /> : null}
    </>
  );
};

export default ChipSection;
