import {CardButtonCoverage, CardButtonPdpCta} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useRouter} from 'next/router';
import {FC, useMemo} from 'react';
import {useCookies} from 'react-cookie';
import {v4 as uuidv4} from 'uuid';
import DrugInteractionsCardButton from './DrugInteractionsCardButton';
import RequestSamplesCardButton from './RequestSamplesCardButton';
import {
  CEVENT_DDI_CHECK,
  DlvDDICheck,
  CEVENT_SIDEPANEL_CTA,
  DlvSidePanelCTA,
  CEVENT_SAMPLES_ELIGIBILITY_CTA,
} from '@/constants';
import {SPLIT_USER_COOKIE} from '@/constants/cookies';
import {
  COVERAGE_RESTRICTIONS_TOOL_ID,
  CTA_SECTIONS_V3,
  PRIOR_AUTORIZATION_FORMS_ID,
  GET_FINANCIAL_ASSISTANCE_ID,
  GET_PATIENT_EDUCATION_RESOURCES_ID,
  PRESCRIBERPOINT_MEDICAL,
} from '@/constants/ctaServices';
import * as FLAGS from '@/constants/flags';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {FULL_SECTION_IDS} from '@/constants/sectionIDs';
import {
  useFlag,
  useDrugInteractionsContext,
  useUserAgentContext,
} from '@/context';
import {
  useCurrentDrug,
  useDlvDrugInfo,
  useCurrentSlug,
  useEnhanced,
  useIsOtc,
  useSectionsStatus,
} from '@/hooks';
import DDIModal from '@/modules/ProductDetailPage/DrugInteractions/DDIModal';
import {useInteractionsByDrug} from '@/queries/useInteractionsByDrug';
import {customEvent} from '@/utils/gtm';
interface ICTASection {
  sectionId: string;
  id: string;
  title: string;
}

interface ICTAMenuV3Props {
  mobileFixedVersion?: boolean;
}

const CTAMenuV3: FC<ICTAMenuV3Props> = ({mobileFixedVersion = false}) => {
  const {isTablet} = useUserAgentContext();
  const {setId, slug} = useCurrentSlug();
  const {genericName} = useCurrentDrug();
  const {setIsOpen} = useDrugInteractionsContext();
  const router = useRouter();
  const {samplesAvailable} = useEnhanced(setId);
  const {drugName, drugSpecialties, pharmaCompany} = useDlvDrugInfo();
  const isOtc = useIsOtc();
  const {data: interactionsData} = useInteractionsByDrug(setId, true);
  const [cookies] = useCookies([SPLIT_USER_COOKIE]);
  const ddiButtonTextExperiment = useFlag(FLAGS.DDI_BUTTON_TEXT_EXPERIMENT);
  const showDDI = useFlag(FLAGS.DDI_TOOL) && interactionsData?.totalItems;
  const showButtonCMOrRS = useFlag(FLAGS.SHOW_CM_OR_RS_BUTTON);
  const samplesURL = new URL(
    `/sample-store/${slug}`,
    PROTOCOL_HOSTNAME,
  ).toString();
  const showNewDDI = useFlag(FLAGS.SHOW_NEW_DDI);
  const medicalSubpage = useFlag(FLAGS.MEDICAL_SUBPAGE);

  const {showPE, showPA, showFASectionLinks, showCoverageOnPdp} = useSectionsStatus();

  const handleClickCheckDrugInteraction = () => {
    if (showNewDDI) {
      //Go to the interaction's page
      router.push('/therapies/' + slug + '/interactions');
    } else {
      //Show modal
      setIsOpen(true);
    }

    customEvent<DlvDDICheck>(CEVENT_DDI_CHECK, {
      drugName,
      pharmaCompany,
      drugSpecialties,
      userKey: cookies[SPLIT_USER_COOKIE],
      experimentName: FLAGS.DDI_BUTTON_TEXT_EXPERIMENT,
      treatment: ddiButtonTextExperiment,
    });
  };

  const handleCTAClick = (ctaSection: ICTASection) => {
    customEvent<DlvSidePanelCTA>(CEVENT_SIDEPANEL_CTA, {
      buttonName: ctaSection.title,
    });

    if (ctaSection.id === COVERAGE_RESTRICTIONS_TOOL_ID && showCoverageOnPdp) {
      document.getElementById(`ss_pivot_coverage`)?.click();
    }

    if (ctaSection.id === PRESCRIBERPOINT_MEDICAL) {
      router.push('/therapies/' + slug + '/medical');
    }

    if (
      [
        PRIOR_AUTORIZATION_FORMS_ID,
        GET_FINANCIAL_ASSISTANCE_ID,
        GET_PATIENT_EDUCATION_RESOURCES_ID,
      ].includes(ctaSection.id)
    ) {
      // Need to call pivot logic due to the pivot menu having conflicts with
      // outside anchor scrolls. Important: when removing pivot menu update this logic!!!
      document.getElementById(`ss_pivot_${ctaSection.sectionId}`)?.click();
    }

    if (mobileFixedVersion) {
      document.getElementById(`${ctaSection.id}`)?.click();
    }
  };

  const ctaSections = useMemo(
    () =>
      CTA_SECTIONS_V3.filter(({sectionId}) => {
        switch (sectionId) {
          case FULL_SECTION_IDS.drugLabel:
            return showButtonCMOrRS;
          case FULL_SECTION_IDS.dosingAndAdmiministration:
            return false;
          case FULL_SECTION_IDS.financialAssistance:
            return showFASectionLinks;
          case FULL_SECTION_IDS.priorAuthorization:
            return showPA;
          case FULL_SECTION_IDS.patientEducation:
            return showPE;
          default:
            return true;
        }
      }).flatMap((section) => {
        if (section.id === PRESCRIBERPOINT_MEDICAL) {
          if (medicalSubpage) {
            return section;
          } else {
            return [];
          }
        }

        return section;
      }),
    [showButtonCMOrRS, showFASectionLinks, showPA, showPE, medicalSubpage],
  );

  const ctaCoverageSection = {
    ...CTA_SECTIONS_V3[0],
    id: COVERAGE_RESTRICTIONS_TOOL_ID,
  };

  const handleSamplesCtaClick = () => {
    customEvent(CEVENT_SAMPLES_ELIGIBILITY_CTA);
    window.location.href = samplesURL;
  };

  const cardButtonClasses = mobileFixedVersion
    ? 'mb-0 h-[46px]'
    : 'h-[90px] sm:h-auto';
  return (
    <div
      className={clsx('flex flex-row sm:mb-6 md:space-x-5', [
        mobileFixedVersion
          ? 'xs:space-x-2'
          : 'grid-flow-cols grid gap-3 md:flex md:gap-0',
      ])}>
      {samplesAvailable && (
        <RequestSamplesCardButton onClick={handleSamplesCtaClick} />
      )}
      {!isOtc && showCoverageOnPdp ? (
        <CardButtonCoverage
          id='card-button-coverage'
          key={`cta-v3-coverage`}
          onClick={() => handleCTAClick(ctaCoverageSection)}
          className={clsx('w-full', [
            showCoverageOnPdp && showDDI ? 'md:w-1/4' : 'md:w-1/3',
          ])}
          drugName={drugName}
        />
      ) : null}
      {showDDI ? (
        <>
          <DrugInteractionsCardButton
            id='check-drug-interactions'
            key={`cta-v2-drug-interactions`}
            onClick={handleClickCheckDrugInteraction}
            className={clsx('w-full', [
              showCoverageOnPdp ? 'md:w-1/4' : 'md:w-1/3',
            ])}
            knownDrugs={showNewDDI}
          />
          <DDIModal
            setId={setId}
            drugName={drugName}
            genericName={genericName}
          />
        </>
      ) : null}

      {!isOtc ? (
        <div
          className={clsx(
            'grid w-full grow grid-cols-2 gap-3 sm:gap-5',
            [showCoverageOnPdp && showDDI ? 'md:w-1/2' : 'md:w-2/3'],
            {
              'sm:grid-cols-3': ctaSections.length === 5,
            },
          )}>
          {ctaSections.map((cta, idx) => (
            <CardButtonPdpCta
              id={mobileFixedVersion ? 'fixed-' + cta.id : cta.id}
              key={`cta-v3-${cta.sectionId}-${uuidv4()}`}
              text={cta.title}
              icon={cta.iconName}
              onClick={() => handleCTAClick(cta)}
              vertical={isTablet}
              className={clsx(cardButtonClasses, {
                'col-span-2': ctaSections.length === 3 && idx === 0,
                'col-span-2 sm:col-span-1':
                  ctaSections.length === 5 && idx === 4,
                'sm:row-span-2': ctaSections.length === 5 && idx === 2,
              })}
              mobileFixedVersion={mobileFixedVersion}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CTAMenuV3;
