import {Text, customEvent} from '@prescriberpoint/ui';
import Image from 'next/image';
import {FC} from 'react';
import MedAIMobileCta from './MedAIMobileCta';
import {CEVENT_BUTTON_CLICKED} from '@/constants';
import {useSectionsStatus} from '@/hooks';

const PrescriberAIMobile: FC = () => {
  const {showButtonAI, showMedAi} = useSectionsStatus();

  if (!showMedAi && !showButtonAI) {
    return;
  }

  const onHandleClick = () => {
    customEvent(CEVENT_BUTTON_CLICKED, {id: 'aiWC_button'});
  };

  return showMedAi ? (
    <MedAIMobileCta />
  ) : (
    <div className='static animate-fadeIn'>
      <div className='fixed bottom-0 left-0 z-20 w-full bg-neutral-lighter p-4'>
        <button
          id='aiWC_button'
          onClick={onHandleClick}
          className='variant m-0 mt-2 inline-flex h-12 w-full cursor-pointer items-center justify-center gap-1 rounded-sm border border-solid border-theme-dark bg-theme-lighter-alt px-7 py-2.5 text-sm font-semibold text-neutral-primary hover:bg-neutral-lighter focus:bg-neutral-light active:bg-neutral-light'>
          <Image
            alt='Prescriber AI'
            width={44}
            height={28}
            className='relative'
            src='https://cdn.prescriberpoint.com/assets/images/prescriber-ai.png'
          />
          <div>
            <Text as='body-md' weight='bold'>
              Prescriber
            </Text>
            <Text as='body-md' weight='semibold'>
              AI
            </Text>
          </div>
        </button>
      </div>
    </div>
  );
};

export default PrescriberAIMobile;
