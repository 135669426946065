import dynamic from 'next/dynamic';
import LabelSection from '../LabelSection';
import Hero from './Hero';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag, usePharmacyContext} from '@/context';
import {
  useAutoOpenChatBox,
  useCurrentSlug,
  useRenderInIFrame,
  useSectionsStatus,
  useShowEnhancedSection,
} from '@/hooks';
import {useIsOncology} from '@/hooks/useIsOncology';

const FASections = dynamic(() => import('../FASections'));
const PatientEducation = dynamic(() => import('../PatientEducation'));
const PeopleAlsoAsk = dynamic(() => import('../PeopleAlsoAsk'));
const PriorAuthorizationSection = dynamic(
  () => import('../PriorAuthorizationSection'),
);
const RequestSample = dynamic(() => import('../RequestSamples/RequestSample'));
const Pharmacy = dynamic(() => import('../Pharmacy'));
const Biomarker = dynamic(() => import('../Biomarker'));
const PubMedNews = dynamic(() => import('../PubMedNews'));
const CoverageRestrictions = dynamic(() => import('../CoverageRestrictions'));
const Ai = dynamic(() => import('./AI'), {ssr: false});

const Sections = () => {
  const {setId} = useCurrentSlug();
  const {isOncologyDrug} = useIsOncology();
  const pdpIsEmbedded = useRenderInIFrame();

  const {
    showPE,
    showPA,
    showFAQS,
    showPubMed,
    showLabel,
    showCoverageOnPdp,
    isCoverageHighlited,
  } = useSectionsStatus();

  const showRS =
    useShowEnhancedSection({
      sectionId: SECTION_IDS.requestSamples,
      flagName: FLAGS.REQUEST_SAMPLES_SECTION,
      checkAvailableResources: true,
    }) && !pdpIsEmbedded;
  const {showPH} = usePharmacyContext();
  const showEF =
    useFlag(FLAGS.BIOMARKER_SECTION) && isOncologyDrug && !pdpIsEmbedded;

  useAutoOpenChatBox();

  return (
    <>
      <Hero />
      {showCoverageOnPdp && !isCoverageHighlited ? (
        <CoverageRestrictions />
      ) : null}
      <Ai />
      {showEF ? <Biomarker /> : null}
      {showLabel ? <LabelSection /> : null}
      {showRS ? <RequestSample /> : null}
      {showPA ? <PriorAuthorizationSection /> : null}
      {showPH && !pdpIsEmbedded ? <Pharmacy /> : null}
      <FASections setId={setId} />
      {showPubMed ? <PubMedNews /> : null}
      {showPE ? <PatientEducation /> : null}
      {showFAQS ? <PeopleAlsoAsk /> : null}
    </>
  );
};

export default Sections;
