import {
  removeAllHTMLElementInStr,
  removeHeaderNumbersAndAnchors,
  removeTableWidthAttribute,
} from '@prescriberpoint/ui';
import DOMPurify from 'isomorphic-dompurify';
import {useMemo} from 'react';
import {useCurrentSlug} from '../useCurrentSlug';
import {useShowEnhancedSection} from './useShowEnhancedSection';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useLabel} from '@/queries';
import {formatDosageHTML} from '@/utils';

export const useShowDosageSection = () => {
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const content = product?.label?.highlights?.dosageAndAdministration;
  const showEnhancedSection = useShowEnhancedSection({
    sectionId: SECTION_IDS.summary,
    checkAvailableResources: false,
  });

  const sanitizedText = useMemo(() => {
    if (!content) {
      return '';
    }
    let safeContent = removeHeaderNumbersAndAnchors(
      removeAllHTMLElementInStr(content, 'h1'),
    ).replace(/\s{9}/g, '');

    safeContent = removeTableWidthAttribute(safeContent);

    return DOMPurify.sanitize(formatDosageHTML(safeContent));
  }, [content]);

  if (showEnhancedSection && !!sanitizedText) {
    return true;
  }

  return false;
};
