import {useEnhanced} from '../useEnhanced';
import {useShowEnhancedSection} from './useShowEnhancedSection';
import * as FLAGS from '@/constants/flags';
import {FULL_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import { useAffordabilityDetails } from '@/queries/useAffordabilityDetails';

export const useShowFASection = (setId: string) => {
  const {enhancedContent, isEnhanced, disableBrandFinancialAssistancePrograms: disabledByEnhancedFlag} =
    useEnhanced(setId);

  const {copaySavingsCardProgram, patientAssistanceProgram} =
    enhancedContent ?? {};

  const showOldFA = useShowEnhancedSection({
    sectionId: FULL_SECTION_IDS.financialAssistance,
    flagName: FLAGS.FINANCIAL_ASSISTANCE_SUPPORT_SECTION,
    useEnhancedFlag: false,
  });

  const showFinderFA = useFlag(FLAGS.AFFORDABILITY_ON_PDP);

  const showNewAffordability = useFlag(FLAGS.AFFORDABILITY_PDP_SECTION);

  const {data: affordabilityData, isPending} = useAffordabilityDetails(setId, {
    enabled: !!setId && showNewAffordability,
  });

  // if there are no programs available, show the generic financial assistance support section
  const programsAvailable = !!(copaySavingsCardProgram || patientAssistanceProgram);

  const showFASectionLinks =
    !disabledByEnhancedFlag &&
    ((showFinderFA && programsAvailable) || showOldFA);

  const sectionIsNull = !isEnhanced || disabledByEnhancedFlag || (showNewAffordability && isPending);

  const showAffordabilitySection = showNewAffordability && !!affordabilityData?.hasPrograms;

  const showFinancialAssistanceSection =
    showFinderFA && (!!copaySavingsCardProgram || !!patientAssistanceProgram);

  return {
    showFASectionLinks,
    showFinancialAssistanceSection,
    showOldFA,
    sectionIsNull,
    showAffordabilitySection,
  };
};
