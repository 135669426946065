import dynamic from 'next/dynamic';
import {FC} from 'react';
import {SectionNew} from '@/components';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import {LABEL_SECTION} from '@/data/label';
import {useCurrentDrug, useEnhanced} from '@/hooks';
import {replaceVariablesInStr} from '@/utils/string';

const Msl = dynamic(() => import('../MSL'));
const ReportAdverseEvent = dynamic(() => import('../ReportAdverseEvent'));
const FDALabelInformationWithoutSSR = dynamic(
  () => import('./FDALabelInformation'),
);

interface LabelSectionProps {}

const LabelSection: FC<LabelSectionProps> = () => {
  const {drugName, setId} = useCurrentDrug();
  const {disableBrandMsl} = useEnhanced(setId, SECTION_IDS.drugLabel);
  const mslFlagValue = useFlag(FLAGS.MEDICAL_SCIENCE_LIAISON_SECTION);
  const showRAE = useFlag(FLAGS.REPORT_ADVERSE_EVENT_SECTION);
  const showMSL = mslFlagValue && !disableBrandMsl;

  return (
    <SectionNew
      title={replaceVariablesInStr(LABEL_SECTION.title, {
        brandName: drugName,
      })}
      id={SECTION_IDS.drugLabel}>
      <FDALabelInformationWithoutSSR />
      <div className='flex h-fit w-full flex-col gap-y-4 pb-4 sm:flex-row sm:gap-x-4 sm:gap-y-0 md:pb-0'>
        {showMSL ? <Msl /> : null}
        {showRAE ? <ReportAdverseEvent /> : null}
      </div>
    </SectionNew>
  );
};

export default LabelSection;
