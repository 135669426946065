import {Chip, Text} from '@prescriberpoint/ui';
import {useMemo} from 'react';
import {HeroIcons, Dosages} from '@/components/';
import {useCurrentDrug, useIsOtc} from '@/hooks';

const ProductTitle = () => {
  const {drugName, genericName} = useCurrentDrug();
  const isOtc = useIsOtc();

  const renderGenericName = useMemo(
    () => drugName.toLocaleLowerCase() !== genericName.toLocaleLowerCase(),
    [drugName, genericName],
  );

  return (
    <div className="flex w-full flex-col items-center">
      <div className="flex h-auto items-center justify-center">
        {isOtc && (
          <Chip label="OTC" size="sm" className="flex h-6 items-center" />
        )}
        <HeroIcons />
      </div>
      <Text tag="h1" as="headline-xl" casing="title" className="text-center">
        {drugName}
      </Text>
      {renderGenericName && (
        <Text as="body-sm" weight="bold">
          ({genericName})
        </Text>
      )}
      <Dosages />
    </div>
  );
};

export default ProductTitle;
