import clsx from 'clsx';
import {FC} from 'react';
import {Footer} from '@/components';
import {NEW_HEADER} from '@/constants/flags';
import {useFlag, useUserAgentContext} from '@/context';
import {
  useCurrentSlug,
  useRenderInIFrame,
  useEnhanced,
  useSectionsStatus,
} from '@/hooks';

const Bottom: FC = () => {
  const {isMobileOrTablet} = useUserAgentContext();
  const {setId} = useCurrentSlug();
  const {newHeaderAvailable} = useEnhanced(setId);
  const showNewHeader = useFlag(NEW_HEADER) && newHeaderAvailable;

  const {showButtonAI} = useSectionsStatus();

  const pdpIsEmbedded = useRenderInIFrame();

  if (pdpIsEmbedded) return null;

  return (
    <div className='w-full'>
      <Footer />
      <div
        className={clsx('bg-neutral-dark md:hidden', {
          'min-h-12': showNewHeader && showButtonAI,
          'min-h-[87px]': showButtonAI && isMobileOrTablet && !showNewHeader,
        })}
      />
    </div>
  );
};

export default Bottom;
