import {useMemo} from 'react';
import {useCurrentSlug} from '../useCurrentSlug';
import {useEnhanced} from '../useEnhanced';
import {FULL_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';

type UseShowEnhancedSectionProps = {
  sectionId: string;
  flagName?: string;
  checkAvailableResources?: boolean;
  useEnhancedFlag?: boolean;
};

export function useShowEnhancedSection({
  sectionId,
  flagName = '',
  checkAvailableResources = true,
  useEnhancedFlag = true,
}: UseShowEnhancedSectionProps) {
  const {setId} = useCurrentSlug();
  const {
    isEnhanced,
    disableBrandFaq,
    disableBrandDosage,
    disableBrandPatientEducation,
    disableBrandLabel,
    disableBrandPriorAuthorizationForms,
    disableBrandFinancialAssistancePrograms,
    disableBrandPubMed,
  } = useEnhanced(setId);
  const {resources} = useEnhanced(setId, sectionId);

  const availableResources = useMemo(
    () =>
      checkAvailableResources
        ? resources.filter(
            (resource) =>
              !resource.tags.find(
                (tag) =>
                  tag.id === 'contentNotAvailable' ||
                  tag.id === 'sectionOtherResources',
              ),
          )
        : resources,
    [resources, checkAvailableResources],
  );

  const sectionFlagMap = useMemo(
    () => ({
      [FULL_SECTION_IDS.peopleAlsoAsk]: !disableBrandFaq,
      [FULL_SECTION_IDS.summary]: !disableBrandDosage,
      [FULL_SECTION_IDS.patientEducation]: !disableBrandPatientEducation,
      [FULL_SECTION_IDS.drugLabel]: !disableBrandLabel,
      [FULL_SECTION_IDS.priorAuthorization]:
        !disableBrandPriorAuthorizationForms,
      [FULL_SECTION_IDS.financialAssistance]:
        !disableBrandFinancialAssistancePrograms,
      [FULL_SECTION_IDS.pubMed]: !disableBrandPubMed
    }),
    [
      disableBrandDosage,
      disableBrandFaq,
      disableBrandPatientEducation,
      disableBrandLabel,
      disableBrandPriorAuthorizationForms,
      disableBrandFinancialAssistancePrograms,
      disableBrandPubMed,
    ],
  );

  const enhancedFlag = useMemo(
    () => {
      if (sectionFlagMap[sectionId] !== undefined) {
        return sectionFlagMap[sectionId];
      }
      return true;
    },
    [sectionFlagMap, sectionId],
  );

  const flagIsOn = useFlag(flagName);
  const showSectionByFeatureFlag = flagName ? flagIsOn : true;

  const showSectionByAvailableResources = checkAvailableResources ? availableResources?.length > 0 : true;
  const showSectionByEnhancedFlag = useEnhancedFlag ? enhancedFlag : true;

  return (
    showSectionByFeatureFlag &&
    isEnhanced &&
    showSectionByAvailableResources &&
    showSectionByEnhancedFlag
  );
}
