import {Text, customEvent} from '@prescriberpoint/ui';
import {BotMessageSquareIcon, ChevronUpIcon} from 'lucide-react';
import {FC} from 'react';
import MedAIMobileCta from './MedAIMobileCta';
import {CEVENT_BUTTON_CLICKED} from '@/constants';
import {useSectionsStatus} from '@/hooks';

const PrescriberAIMobile: FC = () => {
  const {showButtonAI, showMedAi} = useSectionsStatus();

  if (!showMedAi && !showButtonAI) {
    return;
  }

  const onHandleClick = () => {
    customEvent(CEVENT_BUTTON_CLICKED, {id: 'aiWC_button'});
  };

  return showMedAi ? (
    <MedAIMobileCta />
  ) : (
    <div className='static animate-fadeIn'>
      <div className='fixed bottom-0 left-0 z-20 w-full'>
        <button
          id='aiWC_button'
          onClick={onHandleClick}
          className='flex w-full cursor-pointer items-center gap-x-2 rounded-t-[20px] bg-neutral-primary px-5 py-4 text-left'>
          <BotMessageSquareIcon className='text-neutral-lighter' size={20} />
          <Text
            as='body-sm'
            weight='bold'
            className='flex-1 !text-neutral-lighter'>
            Prescriber AI
          </Text>
          <ChevronUpIcon size={24} className='text-neutral-lighter' />
        </button>
      </div>
    </div>
  );
};

export default PrescriberAIMobile;
