import {
  PPT_AI_COMPONENT,
  HIDE_CHATBOT_FOR_NO_ENHANCED,
} from '@/constants/flags';
import {useFlag} from '@/context';
import {useCurrentSlug} from '../useCurrentSlug';
import {useEnhanced} from '../useEnhanced';
import {useIsPfizerDrug} from '../useIsPfizerDrug';
import {useRenderInIFrame} from '../useRenderInIFrame';
import { useShowMedAi } from '../useShowMedAi';

export function useShowAI() {
  const {setId} = useCurrentSlug();
  const pdpIsEmbedded = useRenderInIFrame();
  const {disableAi, isEnhanced} = useEnhanced(setId);
  const showAIPPT = useFlag(PPT_AI_COMPONENT);
  const hideChatbotForNoEnhanced = useFlag(HIDE_CHATBOT_FOR_NO_ENHANCED);
  const isPfizerDrug = useIsPfizerDrug();

  const showMedAi = useShowMedAi(setId);

  const showButtonAIBase =
    showAIPPT && !disableAi && (isEnhanced || !hideChatbotForNoEnhanced);

  const showButtonAI = !isPfizerDrug && showButtonAIBase;

  const showAISection = !pdpIsEmbedded && showButtonAI;

  return {
    showButtonAIBase,
    showButtonAI,
    showAISection,
    showMedAi,
  };
}
