import {IComboBoxOption, Portal, Text, ComboBoxSize} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, useEffect, useMemo} from 'react';
import Icon from '../Icon';

export interface IMobileComboBoxProps {
  options: IComboBoxOption[];
  isOpen: boolean;
  placeholder?: string;
  showClearButton?: boolean;
  optionsHeader?: string;
  size?: ComboBoxSize;
  disabled?: boolean;
  enableFiltering?: boolean;
  optionItemClassName?: ClassValue;
  query: string;
  onQueryChange: (_query: string) => void;
  onSelectItem: (_item: IComboBoxOption) => void;
  onBackClick?: () => void;
  onClearClick?: () => void;
}

const MobileComboBox: FC<IMobileComboBoxProps> = ({
  options,
  isOpen,
  showClearButton = true,
  placeholder,
  optionsHeader,
  size = 'xl',
  disabled = false,
  optionItemClassName,
  enableFiltering = true,
  query,
  onQueryChange,
  onSelectItem,
  onBackClick,
  onClearClick,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function to reset the overflow style when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const onInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onQueryChange?.(e.target.value);
  };

  const handleSelectItem = (item: IComboBoxOption) => {
    onSelectItem(item);
    if (item) {
      onQueryChange(item.value);
    } else {
      onQueryChange('');
    }
  };

  const handleClearInput = () => {
    onClearClick?.();
  };

  const filteredOptions = useMemo(() => {
    if (!enableFiltering) return options;
    return query === ''
      ? options
      : options.filter((item: IComboBoxOption) =>
          item.value.toLowerCase().includes(query.toLowerCase()),
        );
  }, [query, options, enableFiltering]);

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <div>
        <div className='relative z-max bg-white'>
          <div className={clsx('w-svh fixed inset-0 z-max h-svh bg-white')}>
            <div className='flex h-14 gap-x-3 border-0 border-b border-solid border-neutral-lighter px-4'>
              <button className='text-neutral-secondary'>
                <Icon name='arrow-left' size={24} onClick={onBackClick} />
              </button>
              <input
                id='mobile-coverage-input'
                className={clsx(
                  'w-full truncate border-0 border-b border-neutral-lighter font-default text-base font-semibold outline-none placeholder:text-neutral-secondary disabled:placeholder:text-neutral-tertiary',
                  {
                    'h-10 p-2': size === 'md',
                    'h-12 py-3': size === 'lg',
                    'h-14 py-4 pr-6 leading-[26px]': size === 'xl',
                    'cursor-not-allowed bg-neutral-light text-neutral-tertiary':
                      disabled,
                    'text-neutral-primary': !disabled,
                  },
                )}
                value={query}
                onChange={onInputValueChange}
                placeholder={placeholder}
              />
              {showClearButton && query.length > 1 ? (
                <button className='text-neutral-secondary'>
                  <Icon name='x' size={24} onClick={handleClearInput} />
                </button>
              ) : null}
            </div>
            <div className='flex flex-col'>
              {optionsHeader ? (
                <div className='px-4 py-2'>
                  <Text as='body-md' weight='extrabold'>
                    {optionsHeader}
                  </Text>
                </div>
              ) : null}
              <div className='notes-scrollbar flex max-h-[calc(100vh-56px)] flex-col overflow-y-auto'>
                {filteredOptions.map((option) => (
                  <button
                    className={clsx(
                      'flex cursor-pointer select-none items-center border-0 border-b border-solid border-neutral-lighter px-4 py-3 text-left last:border-transparent',
                      optionItemClassName,
                    )}
                    onClick={() => handleSelectItem(option)}
                    key={option.id}>
                    <Text as='body-md' size='md' weight='semibold'>
                      {option.value}
                    </Text>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default MobileComboBox;
